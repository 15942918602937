import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import "../styles/style.css"

const Error404 = () => {
  return (
    <div>
      <Helmet><meta name="robots" content="noindex"/></Helmet>
      <SEO title="Error - Página no encontrada" description="PÁGINA NO ENCONTRADA O DE ACCESO RESTRINGIDO - Si has llegado aquí pinchando en algún enlace, rogamos nos lo comuniques enviando un correo a contacto@qualoom.es"/>
      <div className='background-404'>
        <div className="header_div"><Header /></div>
        <div className="main_error">
          <div className="icon_error_left"><span>:(</span></div>
          <div className="text_error">
            <h1>error 404</h1>
            <p>PÁGINA NO ENCONTRADA O DE ACCESO RESTRINGIDO</p>
          </div>
        </div>
      </div>
      <div className="section-error">
        <div className="section-error-title"><span>Vaya. El recurso que has solicitado no lo hemos encontrado.</span></div>
        <div className="section-error-text"><span>Si has llegado aquí pinchando en algún enlace, te agradeceremos que nos lo comuniques enviando un correo a <a href="mailto:contacto@qualoom.es">contacto@qualoom.es</a> .</span></div>
      </div>
      <Footer/>
    </div>
  )
}

export default Error404
