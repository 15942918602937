import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = (props) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.siteUrl === '' && typeof window !== 'undefined') {
    defaults.siteUrl = window.location.origin;
  }

  if (defaults.siteUrl === '') {
    console.error('Please set a siteUrl in your site metadata!');
    return null;
  }

  const title = props.title || defaults.title;
  const description = props.description || defaults.description;
  const url = new URL(props.path || '', defaults.siteUrl);
  const image = props.image ? new URL(props.image, defaults.siteUrl) : new URL(defaults.image, defaults.siteUrl);
  const twitterimage = url + 'twitter-card.jpg'

  return (
    <Helmet>
      {/*Primary Meta Tags*/}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <html lang='es'/>
      <link rel="canonical" href={url} />
      {image && <meta name="image" content={image} />}
      <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>

      {/*Open Graph / Facebook*/}
      <meta property="og:locale" content="es_ES"/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={twitterimage} />
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="628"/>        
      <meta property="fb:admins" content="338114870907726"/>

      {/*Twitter*/}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {image && <meta property="twitter:image" content={twitterimage} />}
      <meta property="twitter:creator" content="https://twitter.com/qualoom"/>
    </Helmet>
  );
};

export default SEO;